.testimonal-swiper{
    .testimonal-card{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem 5rem;
        border: 1px solid #D86B1F4D;
    
        .testimonal-h2{
            font-size: 1.62rem;
            text-transform: capitalize;
            text-align: center;
            font-weight: 600;
            color: #000000;
            line-height: 2.1rem;
            margin-top: 1.5rem;
        }
        .testimonal-para{
            font-size: 1.26rem;
            margin-top: 0.5rem;
            text-align: center;
            color: #878C9F;
            line-height: 2.4rem;
            font-style: italic;
            // background-color: rgb(17, 17, 17);
        }
        // .user-image-container{
        //     width: 80px;
        //     height: 80px;
        //     border-radius: 100%;

        //     margin: 0 auto;
           
        //     background-size: cover;
        //     background-repeat: no-repeat;
          
            
        // }
    }
    .swiper-pagination-bullets{
        width: fit-content !important;
        
    }
    .swiper-pagination-bullet{
        border: 3px solid rgb(255, 255, 255);
        z-index: 5;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        @media (width<=600px) {
            width: 12px;
            height: 12px;
        }
    }
    .swiper-pagination-bullet-active{
        border: 2px solid var(--primary-cream);
        padding: 0.2rem;
        background-color: transparent;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            width: 60%;
            height: 60%;
            border-radius: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: var(--primary-cream);
        }
    }
   
}
