.home-banner-section{
    .swiper-pagination{
        background-color: red;
        width: fit-content;
    }
    .swiper-pagination-bullet{
        border: 3px solid rgb(255, 255, 255);
        z-index: 5;
        width: 18px;
        height: 18px;
        border-radius: 100%;
        @media (width<=600px) {
            width: 12px;
            height: 12px;
        }
    }
    .swiper-pagination-bullet-active{
        border: 2px solid var(--primary-orange);
        padding: 0.2rem;
        background-color: transparent;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            width: 70%;
            height: 70%;
            border-radius: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: var(--primary-orange);
        }
    }
}

.call-for-resturant{
    display: flex;
    max-width: 100%;
    @media (width<768px) {
        flex-direction: column-reverse;
   }
    .call-para{
        font-size: 1.5rem;
        text-align: center;
        line-height: 3rem;
        font-weight: 400;
        @media (width<=600px) {
            font-size: 1.3rem;
            font-weight: 300;
        }
    }
    .left{
        background-color: var(--primary-green);
        background-image: url("../assets/flower.png");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem ;
        @media (width<768px) {
             width: 100%;
        }
        >div{
            max-width: 660px;
            width: 100%;
            border: 1px solid var(--primary-cream);
            padding: 6rem 0;
            @media (width<600px) {
                padding: 2rem 0;
            }
            .time{
                color: white;
                font-size: 3rem;
                font-weight: 900;
                @media (width<600px) {
                    font-size: 2.2rem;
                    text-align: center;
                }
            }
        }
    }
    .right{
        @media (width<768px) {
            width: 100%;
       }
        .call-image-container{
            background-repeat: no-repeat;
            background-size: cover;
            height: 644px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media (width<768px) {
                height: 380px;
           }
        }
        .swiper-pagination-bullet{
            border: 3px solid rgb(255, 255, 255);
            z-index: 5;
            width: 18px;
            height: 18px;
            border-radius: 100%;
            @media (width<=600px) {
                width: 12px;
                height: 12px;
            }
        }
        .swiper-pagination-bullet-active{
            border: 2px solid var(--primary-orange);
            padding: 0.2rem;
            background-color: transparent;
            position: relative;
            &::after{
                content: "";
                position: absolute;
                width: 70%;
                height: 70%;
                border-radius: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                background-color: var(--primary-orange);
            }
        }
    }
}

.events-container{
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    min-height: 450px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    color: white;
    position: relative;
    z-index: 1;
   
    &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #111111c2;
        z-index: -1;
        opacity: 0.5;
     
    }
    .content-box{
        max-width: 490px;
        background-color: var(--primary-green);
        padding: 0.8rem 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        border-radius: 6px;
        @media (width<=600px) {
            gap: 1rem;
        }
    }
}


 