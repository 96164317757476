.chef-swiper{
    .chef-card{
        .chef-image-container{
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: 1rem;
            @media (width<=768px) {
                height: 400px;
            }
            .chef-details{
                // max-width: 200px;
                background-color: #FFFFFFCC;
                padding: 0.8rem 2.6rem;
                position: absolute;
                bottom: 2rem;
                border-radius: 4px;
                h2{
                    font-size: 1.4rem;
                    font-weight: 600;
                    color: var(--primary-gray);
                    text-transform: capitalize;
                }
            }
        }
    
    } 
    .swiper-pagination-bullet{
        border: 3px solid rgb(255, 255, 255);
        z-index: 5;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        @media (width<=600px) {
            width: 12px;
            height: 12px;
        }
    }
    .swiper-pagination-bullet-active{
        border: 2px solid var(--primary-orange);
        padding: 0.2rem;
        background-color: transparent;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            width: 60%;
            height: 60%;
            border-radius: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            background-color: var(--primary-orange);
        }
    }
}
