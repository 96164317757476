@import "./header.scss";
@import "./footer.scss";
@import "./testimonal.scss";
@import "./home.scss";
@import "./about.scss";

@tailwind base;
@tailwind components;
@tailwind utilities;


*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

:root{
    font-family: "Poppins";
    font-size: 62.4%;
    --primary-gray:#292929;
    --primary-para-gray:#444647;
    --primary-green: #22413F; 
    --primary-cream:#CFAE6F;
    scroll-behavior: smooth;
    font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar{
    background-color: #c3c3c3;
    width: 10px;
    // background-color: transparent;
}

::-webkit-scrollbar-thumb{
    border-radius: 50px;
    border: 1px solid white;
    background-color: #868686;
}

// .bg-freeze{
//   overflow: hidden;
// }

ul{
    list-style: none;
}

a{
   text-decoration: none;
}

.font-playfair{
    font-family: "Playfair Display", serif;
}

.max-width{
    max-width: 1142px;
    margin-inline: auto;
    padding: 0 2rem;
}

.max-width-container{
    max-width: 1237px;
    margin-inline: auto;
    @media (width<=1237px) {
        padding: 0 2rem;
    }
}

.common-section{
    padding: 3rem 0;
    max-width: 1600px;
    margin: 0 auto;
    @media (width<=600px) {
        padding: 1rem 0;
    }
     .common-section-container{
        max-width: 1320px;
        padding: 0 2rem;
        margin-inline: auto;
        display: flex;
        min-height: 418px;
        height: 100%;
        align-items: center;
        gap: 3.4rem;
        @media (width<1024px) {
             flex-direction: column;
        }
        &:nth-child(2){
            flex-direction: row-reverse;
            @media (width<1024px) {
                flex-direction: column;
           }
        }
        &:nth-child(3){
            flex-direction: row-reverse;
            @media (width<1024px) {
                flex-direction: column;
           }
        }
        .left{
            @media (width<1024px) {
               order: 2;
               width: 100%;
           }
        }
        .right{
            box-shadow: -10px 10px 0px #D86B1F1A;   
        }
     }

}

.banner-image-container{
    max-width: 1600px;
    margin: 0 auto;
    height: 660px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat;
    background-position:  top center;
    background-size: cover;
    position: relative;
    z-index: 1;
    @media (width<=1024px) {
          height: 550px;
    }
    @media (width<=600px) {
        height: 280px;
        background-position: top center;
  }
    &::after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #181717;
        z-index: -1;
        opacity: 0.6;
     
      }
    // &::before{
    //   content: "";
    //   position: absolute;
    //   width: 100%;
    //   height: 40px;
    //   background-image: url("../assets/div.brush-dec.png");
    //   background-repeat: no-repeat;
    //   background-size: 100% 100%;
    //   bottom: 0;
    // }

}

.banner-heading-h1{
    font-size: 5rem;
    text-transform: capitalize;
    font-weight: 600;
    text-align: center;
    line-height: 6.2rem;
    @media (width<=600px) {
        font-size: 2.2rem; 
        line-height: 3rem;
    }

}

.banner-para{
    font-size: 1.8rem;
    text-transform: capitalize;
    font-weight: 500;
    line-height: 2.7rem;
    font-style: italic;
    @media (width<=600px) {
        font-size: 1.4rem;
    }
    
}

.section-heading-h2{
    font-size: 1.8rem;
    text-transform: capitalize;
    font-style: italic;
    line-height: 2.4rem;
    font-weight: 500; 
    color: var(--primary-cream);
    @media (width<=600px) {
        font-size: 1.4rem;
        line-height: 1rem;
    }
}

.section-heading-h3{
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 700;
    color: #363636;
    text-transform: capitalize;
    margin-top: 0.3rem;
    @media (width<768px) {
         font-size: 2rem;
         line-height: 3rem;
         margin-top: 0rem;
    }
}

.para{
    font-size: 1.5rem;
    line-height: 2.3rem;
    text-align: justify;
    font-weight: 400;
    color: var(--primary-para-gray);
    @media (width<=600px) {
        font-size: 1.3rem;
        font-weight: 300;
        line-height: 2rem;
    }
}

.btn-color{
    background-color: var(--primary-cream);
    color: white;
    font-size: 1.6rem;
    text-transform: capitalize;
    line-height: 2.1rem;
    font-weight: 500;
    padding: 1.4rem 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;  
    border: 1px solid var(--primary-cream);
    border-radius: 6px;
    &:hover{
        background-color: transparent;
        transition: all 0.2s;
        color: var(--primary-cream);
    }
    @media (width<=600px) {
        font-size: 1.2rem;
    }
}

.btn-active{
    box-shadow: 0px 0px 2px rgb(124, 117, 117);
    &:active{
        scale: 0.85;
     }
}

