nav{
    ul{
        .li-links{
            &:hover{
                color: var(--primary-cream);
                transition: all 0.2s;
            }
            .active{
                color: var(--primary-cream);
            }
            @media (width<=1120px){
                display: none;
            }
        }
    }
    .reservation{
        @media (width<=1124px) {
              display: none;
        }
    }
    .ham-burger-menu{
        display: none;
        @media (width<=1120px) {
             display: block; 
        }
    }
    .search-container{
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba(20, 19, 19, 0.638);
        width: 100%;
        height: 100%;
        backdrop-filter: blur(4px);
        padding: 2rem;
        .input-area{
            height: 50px;
            margin-inline: auto;
            border: 2px solid #D86B1F;
            border-radius: 50px;
            position: relative;
            input{
                width: 100%;
                height: 100%; 
                outline: none;
                font-size: 1.6rem;
                border-radius: 50px;
                background-color: #FFFFFF;
                padding: 0 3rem;
            }
           
        }
        .close{
            font-size: 3.5rem;
        }
    }
    .mobile-nav{
        position: fixed;
        left: 0%;
        width: 100%;
        height: 100vh;
        background-color: #262626;
        background-image: url("../assets//flower.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding: 2rem 4rem;
        z-index: 999999;
       ul{
        display: flex;
        flex-direction: column;
        gap: 2rem;
       }
     
    
    }
}
