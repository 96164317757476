footer{
    position: relative;
    width: 100%;
    position: relative;
    padding: 2rem 0;
    @media (width < 768px) {
        padding: 1rem 0;
    }
    // &::before{
    // content: "";
    // position: absolute;
    // top: 0;
    // width: 100%;
    // height: 50px;
    // background-repeat: no-repeat;
    // background-size: 100% 100%;
    // background-image: url("../assets/div.brush-dec2.png");
    
    // }

    .scroll-to-top{
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        width: 50px;
        height: 50px;
        background-color: var(--primary-cream);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        z-index: 200;
        cursor: pointer;
        .up-icon{
            font-size: 2rem;
            color: white;
            animation: updown 1.5s linear infinite alternate;
            @keyframes updown {
                from{
                    margin-top: 3rem;
                    opacity: 0.5;
                }
                to{
                    margin-top: 0;
                    opacity: 1;
                }
            }
        }
    }
}